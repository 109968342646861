import { colors } from "../assets";
import { currencyToString } from "./currencyToString";

export const calculateChartData = ({ data }) => {
	return data?.map((item) => ({
		date: `${new Date(item?.tanggal).toLocaleString("id-ID", {
			day: "numeric",
			month: "short",
			year: "numeric",
		})}`,
		"Net Asset Value": item?.nav,
		"Line Complementary": item?.nav + Math.floor(Math.random() * 201) - 100,
	}));
};

export const calculateInvestment = ({
	investmentPerMonth,
	durationInYears,
	cagr,
}) => {
	return investmentPerMonth * durationInYears * 12 * ((100 + cagr) / 100);
};

export const calculateProductInfo = ({
	productData,
	navProsentase,
	timeframe,
}) => {
	const topInfo = [
		{
			subtitle: productData?.nama_produk?.split(" ")[0],
			title: productData?.nama_produk?.substr(
				productData?.nama_produk?.indexOf(" ") + 1
			),
		},
		{
			subtitle: "NAV",
			title: productData?.nav?.toFixed(2).toLocaleString(
				"id-ID"
				// {
				// style: "currency",
				// currency: "IDR",
				// }
			),
			trailing: navProsentase && `(${navProsentase?.toFixed(2) ?? "0"}%)`,
			trailingColor: navProsentase >= 0 ? colors.secondary : "#f44336",
		},
	];

	const bottomInfo = [
		{
			subtitle: "CAGR",
			title: `${productData?.cagr?.toFixed(2) ?? "0"}%`,
		},
		{
			subtitle: "Total AUM",
			leading: "Rp",
			title: currencyToString({ num: productData?.aum }),
		},
		{
			subtitle: "Fund Type",
			title: productData?.nama_jenis,
		},
		{
			subtitle: `Return ${timeframe}`,
			title: `${navProsentase?.toFixed(2) ?? "0"}%`,
		},

		// {
		//   subtitle: "Risiko",
		//   title: productData?.tingkat_risiko,
		// },
	];

	return { topInfo, bottomInfo };
};
