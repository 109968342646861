import { CardActionArea, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ParagraphText, TitleText } from "../atoms";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { colors } from "../../assets";

export const EmailCard = ({ title, subtitle, url }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <CardActionArea
      width="100%"
      rel="noopener"
      target="_blank"
      href={url}
      style={{ textDecoration: "none" }}
    >
      <Stack
        direction="row"
        width="100%"
        height={!isMobile ? "150px" : "80px"}
        justifyContent={{ xs: "space-between", md: "space-around" }}
        alignItems="center"
      >
        <Stack spacing={!isMobile ? "16px" : "4px"}>
          <TitleText style={{ fontSize: !isMobile ? "24px" : "14px" }}>
            {title}
          </TitleText>
          <ParagraphText style={{ fontSize: !isMobile ? "20px" : "12px" }}>
            {subtitle}
          </ParagraphText>
        </Stack>

        <ArrowForwardIcon
          style={{
            fontSize: !isMobile ? "24px" : "12px",
            color: colors.primary,
          }}
        />
      </Stack>
    </CardActionArea>
  );
};
