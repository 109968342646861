import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";

import {
  AppBar,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from "@mui/material";
import { HPSLogo, HPAMLogo, colors, myHeroLogo, myHeroColor, HPAMLogoColor } from "../../assets";
import { MobileNavDrawer } from "./MobileNavDrawer";
import { useSelector, useDispatch } from "react-redux";
import { navbarData_en, navbarData_id } from "../../services";

const locales = ["id", "en"];

export const NavBar = () => {
  const companyName = process.env.REACT_APP_COMPANY;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const darkMode = theme.palette.mode === "dark";

  const dispatch = useDispatch();
  const locale = useSelector((state) => state.locale);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [pages, setPages] = useState(navbarData_id);

  useEffect(() => {
    switch (locale) {
      case "id":
        setPages(navbarData_id);
        break;
      case "en":
        setPages(navbarData_en);
        break;
      default:
        setPages(navbarData_id);
        break;
    }
  }, [locale]);

  const handleDrawerToggle = () => setOpenDrawer(!openDrawer);

  return (
    <Box>
      <AppBar
        position="static"
        elevation={0}
        style={{ backgroundColor: "transparent" }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            {/* LOGO */}
            <Stack component={Link} href="/" sx={{ flexGrow: 1 }} justifyContent="center">
                {companyName === "HPS" && <img src={HPSLogo} alt={HPSLogo} />}
                {companyName === "HPAM" && (
                  <img width={120} height={40} src={darkMode ? HPAMLogo : HPAMLogoColor} alt={HPAMLogo} />
                )}
            </Stack>

            {/* NAVIGATION (DESKTOP) */}
            {!isMobile && (
              <Box sx={{ flexGrow: 1, display: "flex" }}>
                {pages.map((page) => (
                  <Link
                    key={page?.route}
                    href={page?.route}
                    to={page?.route}
                    target={page?.target}
                    style={{ textDecoration: "none" }}
                  >
                    <Container>
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontSize: "16px",
                        }}
                        color={darkMode ? "inherit" : "primary.main"}
                      >
                        {page?.label}
                      </Typography>
                    </Container>
                  </Link>
                ))}
              </Box>
            )}

            {!isMobile && (
              <Stack direction="row" spacing={2}>
                {/* LANGUAGE SELECTION (DESKTOP) */}
                <FormControl size="small" style={{ minWidth: "80px" }}>
                  <Select
                    value={locale}
                    onChange={(e) => {
                      dispatch({ type: "set", locale: e.target.value });
                    }}
                    sx={{ borderRadius: "50px", display: "inherit" }}
                  >
                    {locales.map((locale, index) => (
                      <MenuItem key={index} value={locale}>
                        {locale?.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* MYHERO BUTTON */}
                <Button
                  variant="outlined"
                  href="https://myhero.id"
                  rel="noopener"
                  target="_blank"
                  style={{
                    textTransform: "none",
                    borderRadius: "100px",
                    borderColor: darkMode ? colors.gray3 : colors.gray2,
                  }}
                >
                  <img
                    width={71}
                    height={16}
                    src={darkMode ? myHeroLogo : myHeroColor}
                    alt="myHero Logo"
                  />
                </Button>
              </Stack>
            )}

            {/* MENU BUTTON (MOBILE) */}
            {isMobile && (
              <IconButton
                size="small"
                sx={{ display: "flex" }}
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      {/* NAVIGATION (MOBILE) */}
      <Box component="nav">
        <MobileNavDrawer
          pages={pages}
          toggle={handleDrawerToggle}
          isOpen={openDrawer}
        />
      </Box>
    </Box>
  );
};
