export const currencyToString = ({
  num,
  locale = "id-ID",
  // currency = "IDR",
  // minimumFractionDigits = 2,
  // maximumFractionDigits,
  abbreviationFormats = [
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ],
}) => {
  if (num == null || typeof num !== "number") {
    // null, undefined, non-numeric, return what was provided
    return num;
  }

  let format;
  if (abbreviationFormats != null) {
    // formats were provided, find one that works
    format = abbreviationFormats.find((f) => num >= f.value);
  }

  if (format != null) {
    // apply the format, insert the symbol next to the numeric portion of the formatted string
    const { value, symbol } = format;
    const formatted = (num / value).toFixed(2).toLocaleString(
      locale
      // {
      // style: "currency",
      // currency,
      // minimumFractionDigits,
      // maximumFractionDigits,
      // }
    );
    const parts = formatted.match(/([\D]*)([\d.,]+)([\D]*)/);
    return `${parts[1]}${parts[2]}${symbol}${parts[3]}`;
  }

  // otherwise, use the number as provided
  return num.toFixed(2).toLocaleString(
    locale
    // {
    // style: "currency",
    // currency,
    // minimumFractionDigits,
    // maximumFractionDigits,
    // }
  );
};
