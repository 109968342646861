/* eslint-disable no-undef */

//png
export const HPSLogo = require("./hps.png");
export const ojkLogo = require("./ojk.png");
export const kominfoLogo = require("./kominfo.png");
export const myHeroLogo = require("./myHero.png");
export const googlePlayButton = require("./googlePlay.png");
export const appStoreButton = require("./appStore.png");
export const HPSHomeBg = require("./HPSHomeBg.png");
export const HPSHomeBgMobile = require("./HPSHomeBgMobile.png");
export const HeaderBg = require("./headerBg.png");
export const HPAMHomeBg = require("./HPAMHomeBg.png");
export const HPAMHomeBgMobile = require("./HPAMHomeBgMobile.png");
export const MapBg = require("./mapBg.png");
export const HPAMColor = require("./hpam-c.png");
//svg
export const HPAMLogo = require("./svg/hpam.svg").default;
export const HPAMLogoColor = require("./svg/hpam-c.svg").default;
export const instagram_icon = require("./svg/instagram_icon.svg").default;
export const pin_icon = require("./svg/pin_icon.svg").default;
export const linkedin_icon = require("./svg/linkedin_icon.svg").default;
export const youtube_icon = require("./svg/youtube_icon.svg").default;
export const quote_icon = require("./svg/quote.svg").default;
export const myHeroColor = require("./svg/myhero-c.svg").default;
