import { Card, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets";
import { calculateInvestment } from "../../services";
import { ParagraphText, TitleText } from "../atoms";
import { InvestCalcTextField } from "../molecules";

export const InvestmentCalculator = ({ cagr }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [investmentPerMonth, setInvestmentPerMonth] = useState(1000000);
  const [durationInYears, setDurationInYears] = useState(3);
  const [investmentAmount, setInvestmentAmount] = useState(0);

  useEffect(() => {
    const investment = calculateInvestment({
      investmentPerMonth,
      durationInYears,
      cagr: cagr,
    });
    setInvestmentAmount(investment);
  }, [investmentPerMonth, durationInYears, cagr]);

  return (
    <Card style={{ padding: 32 }}>
      <Stack
        spacing={!isMobile ? "64px" : "32px"}
        style={{
          paddingTop: !isMobile ? "32px" : "16px",
          paddingBottom: !isMobile ? "32px" : "16px",
        }}
      >
        {/* INVESTMENT CALCULATOR */}
        <TitleText>Mari Kita Simulasikan</TitleText>

        <Stack
          spacing={5}
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction={{ xs: "column", sm: "row" }} spacing={5}>
            <InvestCalcTextField
              label="Kamu investasi sebesar..."
              heading="Rp"
              trailing="/bulan"
              value={investmentPerMonth}
              onChange={(e) => setInvestmentPerMonth(e.target.value)}
            />

            <InvestCalcTextField
              label="Selama..."
              trailing="tahun"
              value={durationInYears}
              onChange={(e) => setDurationInYears(e.target.value)}
            />
          </Stack>

          <Stack style={{ width: isMobile ? "100%" : undefined }}>
            <ParagraphText style={{ fontSize: "20px" }}>
              Kamu akan dapat...
            </ParagraphText>
            <TitleText style={{ color: colors.gray1, fontSize: "32px" }}>
              {(investmentAmount ?? 0)?.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
              })}
            </TitleText>
          </Stack>
        </Stack>
        <Stack style={{ width: isMobile ? "100%" : undefined }}>
            <ParagraphText style={{ fontSize: isMobile? "12px" : "20px" }}>
              *Simulasi dibuat berdasarkan kinerja masa lalu yang dihitung dengan CAGR (Compound Annual Growth Rate) dan tidak menjamin kinerja di masa depan
            </ParagraphText>
          </Stack>
      </Stack>
    </Card>
  );
};
