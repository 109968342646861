import { Container } from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";
import { ParagraphText } from "../atoms";

const ErrorPage = () => {
  React.useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Stack alignItems="center">
        <ParagraphText>
          The page you are looking for cannot be found.
        </ParagraphText>
      </Stack>
    </Container>
  );
};

export default ErrorPage;
