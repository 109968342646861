import React from "react";
import {
	Grid,
	Link,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";

export const ArticleCard = ({ item, top, style, imageStyle }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<>
			{top ? (
				<Grid item xs={1} sm={3} style={{ paddingTop: "60px" }}>
					<Link
						href={`/blog/${item?.attributes?.slug}`}
						style={{ textDecoration: "none", paddingLeft: "24px" }}
					>
						<Grid container columns={{ xs: 1, md: 3 }} spacing="24px">
							<Grid item xs md={2}>
								<img
									src={item?.attributes?.image?.data?.attributes?.url}
									alt={
										item?.attributes?.image?.data?.attributes?.alternativeText
									}
									style={{
										width: "100%",
										height: "360px",
										objectFit: "cover",
										...imageStyle,
									}}
								/>
							</Grid>
							<Grid item xs={1}>
								<Stack spacing="20px">
									<Typography
										style={{
											fontSize: !isMobile ? "32px" : "16px",
											fontWeight: 600,
										}}
										color="primary.main"
									>
										{item?.attributes?.title}
									</Typography>
									<Typography
										style={{
											fontSize: !isMobile ? "16px" : "12px",
											fontWeight: 400,
										}}
									>
										{item?.attributes?.subtitle}
									</Typography>
									<Typography
										style={{
											fontSize: !isMobile ? "16px" : "12px",
											fontWeight: 500,
											lineHeight: "20.16px",
											color: "#035499",
										}}
									>
										{`${new Date(
											item?.attributes?.publishedAt
										).toLocaleDateString("id-ID", {
											year: "numeric",
											month: "short",
											day: "numeric",
										})} `}
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					</Link>
				</Grid>
			) : (
				<Link
					href={`/blog/${item?.attributes?.slug}`}
					style={{ textDecoration: "none" }}
				>
					<Grid item xs={3}>
						<Stack spacing="10px" style={style}>
							<img
								src={item?.attributes?.image?.data?.attributes?.url}
								alt={item?.attributes?.image?.data?.attributes?.alternativeText}
								style={{
									height: "250px",
									width: "100%",
									objectFit: "cover",
									...imageStyle,
								}}
							/>
							{/* <Stack direction={"column"} style={{ paddingTop: 16 }}> */}
							<Typography
								style={{
									fontWeight: 600,
									fontSize: "16px",
									display: "-webkit-box",
									overflow: "hidden",
									WebkitBoxOrient: "vertical",
									WebkitLineClamp: 1,
								}}
								color="primary.main"
							>
								{item?.attributes?.title}
							</Typography>
							<Typography
								style={{
									fontWeight: 400,
									fontSize: "12px",
									display: "-webkit-box",
									overflow: "hidden",
									WebkitBoxOrient: "vertical",
									WebkitLineClamp: 2,
								}}
							>
								{item?.attributes?.subtitle}
							</Typography>
							<Typography
								style={{
									fontSize: "12px",
									fontWeight: 500,
									color: "#035499",
								}}
							>
								{`${new Date(item?.attributes?.publishedAt).toLocaleDateString(
									"id-ID",
									{
										year: "numeric",
										month: "short",
										day: "numeric",
									}
								)} `}
							</Typography>
						</Stack>
					</Grid>
				</Link>
			)}
		</>
	);
};
