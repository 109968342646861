import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { CustomNumberFormat } from "../../services";
import PropTypes from "prop-types";
import { ParagraphText } from "../atoms";

export const InvestCalcTextField = ({
  label,
  heading,
  trailing,
  value,
  onChange,
}) => {
  return (
    <TextField
      variant="standard"
      name="numberformat"
      value={value}
      onChange={onChange}
      size="normal"
      label={
        <ParagraphText style={{ fontSize: "24px" }}>{label}</ParagraphText>
      }
      InputProps={{
        style: {
          fontWeight: 600,
          fontSize: "32px",
          color: colors.gray1,
        },
        inputComponent: CustomNumberFormat,
        startAdornment: (
          <InputAdornment position="start">
            <ParagraphText style={{ color: colors.gray1, fontSize: "20px" }}>
              {heading}
            </ParagraphText>
          </InputAdornment>
        ),
        endAdornment: trailing ? (
          <InputAdornment position="end">
            <ParagraphText style={{ color: colors.gray1, fontSize: "20px" }}>
              {trailing}
            </ParagraphText>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

InvestCalcTextField.propTypes = {
  label: PropTypes.string,
  heading: PropTypes.string,
  trailing: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};
