import { Stack } from "@mui/material";
import React from "react";

export const FooterIcons = ({ items }) => {
  return (
    <Stack direction="row" gap="32px">
      {items?.map((item, index) => (
        <a
          key={index}
          href={item?.url}
          aria-label={item?.ariaLabel}
          target="_blank"
          rel="noreferrer"
        >
          {item?.icon}
        </a>
      ))}
    </Stack>
  );
};
