import React from "react";
import PropTypes from "prop-types";
import { ParagraphText } from "../atoms/ParagraphText";
import { Stack } from "@mui/material";
import { colors } from "../../assets";
import { TitleText } from "../atoms";

export const InfoText = ({ subtitle, title, leading, trailing }) => {
	return (
    <Stack>
      <ParagraphText style={{ fontWeight: 500, color: "white" }}>
        {subtitle}
      </ParagraphText>

      <Stack direction="row" alignItems="flex-end" spacing={1}>
        {leading && (
          <ParagraphText style={{ fontWeight: 600 }}>{leading}</ParagraphText>
        )}

        <TitleText style={{ color: colors?.primary }} top>
          {title}
        </TitleText>

        {trailing && <ParagraphText>{trailing}</ParagraphText>}
      </Stack>
    </Stack>
  );
};

InfoText.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  leading: PropTypes.string,
  leadingColor: PropTypes.string,
  trailing: PropTypes.string,
  trailingColor: PropTypes.string,
};
