/* eslint-disable no-template-curly-in-string */
import {
  Chip,
  CircularProgress,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  AreaChart,
  Area,
} from "recharts";
import { colors } from "../../assets";

export const StockChart = ({ isLoading, data, timeframe, setTimeframe }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const timeframeList = ["1D", "1M", "3M", "YTD", "1Y", "3Y", "5Y"];

  return (
    <Stack spacing="12px">
      {/* CHART */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: isMobile ? "200px" : "450px",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <AreaChart data={data}>
              <XAxis
                dataKey="date"
                minTickGap={30}
                style={{ fontFamily: "TT Commons" }}
              />
              <YAxis domain={["auto", "auto"]} hide />
              <Tooltip />
              <Legend
                align="start"
                iconType="circle"
                wrapperStyle={{ paddingTop: "8px", fontFamily: "TT Commons" }}
              />
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={colors.primary}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={colors.primary}
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="Net Asset Value"
                stroke={colors.primary}
                fillOpacity={1}
                fill="url(#gradient)"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>

      {/* CHART TIMEFRAME BUTTONS */}
      <Stack
        direction="row"
        spacing={1}
        style={{ justifyContent: !isMobile ? "end" : "space-between" }}
      >
        {timeframeList.map((item, index) => (
          <Chip
            key={index}
            label={item}
            variant={timeframe !== item ? "outlined" : "filled"}
            color={timeframe === item ? "primary" : "default"}
            clickable
            onClick={() => setTimeframe(item)}
          />
        ))}
      </Stack>
    </Stack>
  );
};
