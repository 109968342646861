import {
  Container,
  ImageList,
  ImageListItem,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { CarouselContainer, BasicCard, SectionHeader } from "../molecules";

export const ProductsSection = ({ id, title, subtitle, items }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedSlide, setSelectedSlide] = useState(0);

  return (
    <Stack
      id={id}
      spacing={!isMobile ? "120px" : "60px"}
      alignItems="center"
      style={{
        width: "100%",
        backgroundColor: "black",
        padding: !isMobile ? "120px 0" : "60px 0",
      }}
    >
      <Container maxWidth="lg">
        <SectionHeader title={title} subtitle={subtitle} />

        {!isMobile ? (
          <CarouselContainer
            style={{ height: "360px", margin: "120px 0" }}
            selectedSlide={selectedSlide}
            setSelectedSlide={setSelectedSlide}
          >
            {items?.map((item, index) => (
              <BasicCard
                key={item?.id}
                title={item?.title}
                subtitle={item?.subtitle}
                imageSrc={item?.icon?.data?.attributes?.url}
                href={
                  selectedSlide === index
                    ? `product/${item?.redirect_to}`
                    : undefined
                }
                style={{ height: "380px", width: "270px" }}
              />
            ))}
          </CarouselContainer>
        ) : (
          <ImageList sx={{ gridAutoFlow: "column", margin: 0 }}>
            {items?.map((item, index) => (
              <ImageListItem
                key={index}
                style={{ margin: "16px 0", width: "240px" }}
              >
                <BasicCard
                  title={item?.title}
                  subtitle={item?.subtitle}
                  imageSrc={item?.icon?.data?.attributes?.url}
                  href={`product/${item?.redirect_to}`}
                  style={{ height: "270px", margin: "8px" }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Container>
    </Stack>
  );
};
