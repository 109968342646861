import React, { useEffect, useState } from "react";
import { Box, Container, Stack, useTheme, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { TitleText } from "../atoms";
import {
  HpamFooterData_id,
  HpamFooterData_en,
  HpsFooterData_id,
  HpsFooterData_en,
} from "../../services";
import { FooterIcons, FooterImages, FooterTexts } from "../molecules";
import { useSelector } from "react-redux";

export const Footer = ({ showDisclaimer = false }) => {
  const companyName = process.env.REACT_APP_COMPANY;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const locale = useSelector((state) => state.locale);

  const [footerData, setFooterData] = useState(HpamFooterData_id);

  useEffect(() => {
    switch (companyName) {
      case "HPAM":
        if (locale === "id") {
          setFooterData(HpamFooterData_id);
        } else if (locale === "en") {
          setFooterData(HpamFooterData_en);
        }
        break;
      case "HPS":
        if (locale === "id") {
          setFooterData(HpsFooterData_id);
        } else if (locale === "en") {
          setFooterData(HpsFooterData_en);
        }
        break;
      default:
        setFooterData(HpamFooterData_id);
        break;
    }
  }, [companyName, locale]);

  return (
    <Box
      style={{
        backgroundColor: "black",
        padding: !isMobile ? "120px 0" : "60px 0",
      }}
    >
      <Container maxWidth="lg">
        {/* FOOTER ITEMS */}
        <Stack
          gap="64px"
          flexWrap="wrap"
          direction="row"
          justifyContent={!isMobile ? "space-between" : "flex-start"}
        >
          {footerData
            ?.filter(
              (item) =>
                !item?.hide &&
                !(item?.contentsType === "disclaimer" && !showDisclaimer)
            )
            ?.map((item, index) => (
              <Stack key={index} gap="32px">
                {/* TITLE */}
                {item?.title && (
                  <TitleText style={{ fontSize: "20px", color: "white" }}>
                    {item?.title}
                  </TitleText>
                )}

                {/* CONTENTS */}
                {item?.contents &&
                  (item?.contentsType === "icon" ? (
                    <FooterIcons items={item?.contents} />
                  ) : item?.contentsType === "image" ? (
                    <FooterImages items={item?.contents} />
                  ) : (
                    <FooterTexts items={item?.contents} />
                  ))}
              </Stack>
            ))}
        </Stack>
      </Container>
    </Box>
  );
};

Footer.propTypes = {
  showDisclaimer: PropTypes.bool,
};
