import { Box, Container } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { colors, HeaderBg } from "../../assets";
import { TitleText } from "../atoms";

export const PageHeader = ({ style, title }) => {
  return (
    <Box
      style={{
        height: "200px",
        backgroundColor: colors.lightGray,
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        backgroundImage: `url(${HeaderBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        ...style,
      }}
    >
      <Container maxWidth="lg">
        <TitleText style={{ padding: "42px 0", color: "white" }} top>
          {title}
        </TitleText>
      </Container>
    </Box>
  );
};

PageHeader.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
};
