import {
  Box,
  Button,
  Container,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  HPAMHomeBg,
  HPAMHomeBgMobile,
  HPSHomeBg,
  HPSHomeBgMobile,
} from "../../assets";
import { ParagraphText, TitleText } from "../atoms";

export const TitleBanner = ({ title, subtitle, buttonText, buttonUrl }) => {
  const companyName = process.env.REACT_APP_COMPANY;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [HomeBg, setHomeBg] = useState(HPAMHomeBg);

  useEffect(() => {
    if (!isMobile) {
      if (companyName === "HPAM") setHomeBg(HPAMHomeBg);
      else if (companyName === "HPS") setHomeBg(HPSHomeBg);
    } else {
      if (companyName === "HPAM") setHomeBg(HPAMHomeBgMobile);
      else if (companyName === "HPS") setHomeBg(HPSHomeBgMobile);
    }
  }, [companyName, isMobile]);

  return (
    <Box
      style={{
        width: "100%",
        marginTop: "-64px",
        paddingTop: "64px",
        backgroundImage: `url(${HomeBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        // backgroundAttachment: "fixed",
      }}
    >
      <Container
        maxWidth="lg"
        style={{
          alignItems: !isMobile ? "start" : "center",
          textAlign: !isMobile ? "start" : "center",
        }}
      >
        <Stack
          spacing={!isMobile ? "60px" : "24px"}
          minHeight={!isMobile ? "800px" : "450px"}
          justifyContent={!isMobile ? "space-around" : "end"}
          style={{ paddingBottom: isMobile && "32px" }}
        >
          <Stack spacing={!isMobile ? "60px" : "24px"} justifyContent="center">
            <TitleText style={{ fontSize: !isMobile ? "48px" : "36px" }} top>
              {title}
            </TitleText>
            <ParagraphText>{subtitle}</ParagraphText>
          </Stack>

          <div>
            <Button
              href={buttonUrl}
              target="_blank"
              rel="noopener noreferrer"
              size="large"
              variant="contained"
              style={{
                borderRadius: "100px",
                textTransform: "inherit",
                fontSize: !isMobile ? "24px" : "16px",
              }}
            >
              {buttonText}
            </Button>
          </div>
        </Stack>
      </Container>
    </Box>
  );
};
