import {
  ImageList,
  ImageListItem,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";
import { BasicCard, ThumbnailCard } from "../molecules";

export const InsightList = ({
  categories,
  selectedCategory,
  insights,
  style,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const filteredCategories = categories?.filter((category) =>
    category?.title?.includes(selectedCategory)
  );

  if (!(filteredCategories?.length > 0)) {
    return (
      <ParagraphText style={{ fontStyle: "italic", color: colors.gray3 }}>
        No data found.
      </ParagraphText>
    );
  }

  return (
    <Stack spacing="48px" style={{ overflowX: "hidden", ...style }}>
      {filteredCategories?.map((category) => {
        const filteredInsights = insights?.filter(
          (item) =>
            item?.categories?.data[0]?.attributes?.title === category?.title
        );

        return (
          <Stack
            key={category?.order_number}
            direction={!isMobile ? "row" : "column"}
            gap="24px"
          >
            {/* RESEARCH CATEGORY TITLE CARD */}
            {!isMobile ? (
              <BasicCard
                title={category?.title}
                subtitle={category?.subtitle || category?.description}
                imageSrc={category?.image_url?.data?.attributes?.url}
                titleFontSize="24px"
                alignItems="start"
                style={{ width: "240px", height: "360px", flex: "none" }}
              />
            ) : (
              <TitleText>{category?.title}</TitleText>
            )}

            {/* RESEARCH LIST */}

            {!(filteredInsights?.length > 0) ? (
              <ParagraphText
                style={{ fontStyle: "italic", color: colors.gray3 }}
              >
                No data found.
              </ParagraphText>
            ) : (
              <ImageList gap={12} sx={{ gridAutoFlow: "column", margin: 0 }}>
                {filteredInsights?.map((item, index) => (
                  <ImageListItem key={index} style={{ marginBottom: "16px" }}>
                    <ThumbnailCard
                      title={item?.title}
                      style={{ width: "180px" }}
                      imgSrc={item?.thumbnail_url?.data?.attributes?.url}
                      href={`/insight/${item?.id}`}
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
