import { Container, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingAnimation, PageHeader } from "../molecules";
import { apiUrls } from "../../services/apiUrls";
import { FilterCard, InsightList } from "../organisms";
import axios from "axios";

export const Insight = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [selectedCategory, setSelectedCategory] = useState("");
	const [categoriesData, setCategoriesData] = useState();
	const [insightsData, setInsightsData] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);

			const categoriesResponse = await axios.get(apiUrls.insight.getCategories);
			const categoriesList = categoriesResponse?.data?.data?.map((data) => ({
				id: data?.id,
				...data?.attributes,
			}));
			setCategoriesData(categoriesList);

			const insightsResponse = await axios.get(apiUrls.insight.getInsights);
			const insightsList = insightsResponse?.data?.data?.map((data) => ({
				id: data?.id,
				...data?.attributes,
			}));
			setInsightsData(insightsList);

			setIsLoading(false);
		};

		fetchData();
	}, []);

	const filteredInsightList = insightsData?.filter((insight) => {
		if (searchText?.length > 0) {
			return insight?.title?.toLowerCase().includes(searchText?.toLowerCase());
		} else {
			return insightsData;
		}
	});

	return (
		<>
			<PageHeader title="Research" />

			{isLoading ? (
				<LoadingAnimation />
			) : (
				<Container maxWidth="lg">
					<Stack
						spacing="32px"
						direction={{ xs: "column", sm: "row" }}
						style={{ margin: !isMobile ? "64px 0" : "32px 0" }}
					>
						{/* FILTER CARD */}
						<FilterCard
							items={categoriesData?.map((item) => item?.title)}
							selectedItem={selectedCategory}
							setSelectedItem={setSelectedCategory}
							setSearchText={setSearchText}
							searchText={searchText}
							style={{ flex: "none" }}
						/>

						{/* INSIGHT LIST */}
						<InsightList
							categories={categoriesData}
							selectedCategory={selectedCategory}
							insights={filteredInsightList}
						/>
					</Stack>
				</Container>
			)}
		</>
	);
};
