import { Link, Stack } from "@mui/material";
import React from "react";
import { ParagraphText } from "../atoms";

export const FooterTexts = ({ items }) => {
  return (
    <Stack gap="24px">
      {items?.map((item, index2) => (
        <Link
          key={item?.text + index2}
          href={item?.url}
          color="inherit"
          variant="inherit"
          underline="none"
          aria-label={item?.ariaLabel}
          target="_blank"
          rel="noopener"
        >
          <Stack direction="row" gap="24px">
            {item?.icon && item?.icon}
            <ParagraphText style={{ fontSize: "16px" }}>
              {item?.text}
            </ParagraphText>
          </Stack>
        </Link>
      ))}
    </Stack>
  );
};
