import {
  Box,
  Container,
  Divider,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { colors, MapBg } from "../../assets";
import { EmailCard, SectionHeader } from "../molecules";

export const ContactSection = ({
  id,
  title,
  subtitle,
  googleMapUrl,
  contactList,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      id={id}
      spacing={!isMobile ? "120px" : "60px"}
      alignItems="center"
      style={{
        padding: !isMobile ? "120px 0" : "60px 0",
        width: "100%",
        backgroundColor: "black",
      }}
    >
      <Container maxWidth="lg" style={{ width: "100%" }}>
        <SectionHeader title={title} subtitle={subtitle} />
      </Container>

      <Container width="100%">
        <Box
          style={{
            height: "600px",
            width: "100%",
            backgroundColor: colors.lightGray,
            display: "flex",
            backgroundImage: `url(${MapBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            cursor: "pointer",
          }}
          onClick={() => window.open(googleMapUrl, "_blank")}
        ></Box>
      </Container>

      {/* EMAILS */}
      <Container maxWidth="lg">
        <Stack
          width="100%"
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          divider={
            <Divider
              orientation={!isMobile ? "vertical" : "horizontal"}
              flexItem
            />
          }
        >
          {contactList?.map((item, index) => (
            <EmailCard
              key={index}
              title={item?.title}
              subtitle={item?.subtitle}
              url={`mailto:${item?.title}`}
            />
          ))}
        </Stack>
      </Container>
    </Stack>
  );
};
