/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from "react";
import { Box, Container, Stack } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { colors } from "../../assets";
import axios from "axios";
import { apiUrls } from "../../services/apiUrls";
import { LoadingAnimation, PageHeader } from "../molecules";
import { TitleText } from "../atoms";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const InsightPdf = () => {
	const [pdfData, setPdfData] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const { id } = useParams();

	const [numPages, setNumPages] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);

			const response = await axios.get(
				apiUrls.insightPdf.getPdf.replace("${id}", id)
			);
			const data = response?.data?.data?.attributes;
			setPdfData(data);

			setIsLoading(false);
		};

		fetchData();
	}, [id]);

	return (
    <Box>
      <PageHeader title={"Research"} top />

      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Container maxWidth="lg" style={{ marginTop: "64px" }}>
          <Stack spacing="32px">
            <TitleText style={{ textAlign: "center" }}>
              {pdfData?.title}
            </TitleText>

            <Stack
              direction="row"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Link to="/insight" style={{ textDecoration: "none" }}>
                <TitleText style={{ fontSize: "16px", color: colors.primary }}>
                  Kembali
                </TitleText>
              </Link>

              <Link style={{ textDecoration: "none" }}>
                <TitleText style={{ fontSize: "16px", color: colors.primary }}>
                  Bagikan
                </TitleText>
              </Link>
            </Stack>

            {pdfData?.pdf_url?.data?.attributes?.url && (
              <Document
                file={pdfData?.pdf_url?.data?.attributes?.url}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                <Stack gap="32px">
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page, index) => (
                      <Page key={index} pageNumber={page} scale={3} />
                    ))}
                </Stack>
              </Document>
            )}
          </Stack>
        </Container>
      )}
    </Box>
  );
};
