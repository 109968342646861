import { Card, CardActionArea, CardContent, Stack } from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { TitleText } from "./TitleText";
import PropTypes from "prop-types";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

export const DocDownloadButton = ({ label, url }) => {
  return (
    <Card style={{ backgroundColor: colors.gray4, width: "100%" }}>
      <CardActionArea href={url} target="_blank" rel="noopener noreferrer">
        <CardContent style={{ padding: "24px" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <TitleText
              style={{
                fontWeight: 700,
                fontSize: "20px",
                color: "white",
              }}
            >
              {label}
            </TitleText>
            <ArrowCircleDownIcon style={{ color: "white", fontSize: "20px" }} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

DocDownloadButton.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
};
