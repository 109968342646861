import * as React from "react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { MobileStepper, Fab } from "@mui/material";
import { colors } from "../../assets";

export function CarouselStepper({ index, setIndex, length, style }) {
  const handleNext = () => {
    setIndex((prev) => (index === length - 1 ? 0 : prev + 1));
  };

  const handleBack = () => {
    setIndex((prev) => (index === 0 ? length - 1 : prev - 1));
  };

  return (
    <MobileStepper
      variant={length > 8 ? "progress" : "dots"}
      steps={length}
      position="static"
      activeStep={index}
      sx={{
        margin: 0,
        padding: 0,
        maxWidth: "200px",
        backgroundColor: "transparent",
        "& .MuiMobileStepper-dotActive": {
          backgroundColor: "#fff",
        },
        ...style,
      }}
      nextButton={
        <Fab
          size="small"
          aria-label="next"
          style={{ backgroundColor: colors.gray3 }}
          onClick={handleNext}
        >
          <KeyboardArrowRight style={{ color: "white" }} />
        </Fab>
      }
      backButton={
        <Fab
          size="small"
          aria-label="back"
          style={{ backgroundColor: colors.gray3 }}
          onClick={handleBack}
        >
          <KeyboardArrowLeft style={{ color: "white" }} />
        </Fab>
      }
    />
  );
}
