/* eslint-disable no-template-curly-in-string */
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { apiUrls } from "../../services/apiUrls";
import axios from "axios";

export const Shortlink = () => {
    const { shortlink } = useParams();
    const navigate = useNavigate();

    const [shortlinkData, setShortlinkData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(apiUrls.shortlink.getShortlink.replace("${shortlink}", shortlink));
                setShortlinkData(response?.data?.data);
            } catch (error) {
                setShortlinkData(null)
            }

            if(shortlinkData?.attributes?.destination) {
                window.location.href = shortlinkData?.attributes?.destination;
            } else {
                window.location.href = "/";
            }
        }

        fetchData();
    }, [shortlink, navigate, shortlinkData?.attributes?.destination]) 

    return <></>;
};
